/*jslint devel: true */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				$("a.scrollToTop").click(function(e) {
					$("html, body").animate({ scrollTop: 0 }, "slow");
					e.preventDefault();
				});

				// Bootstrap dropdowns on hover
				$('ul.nav li.dropdown').hover(function() {
					$(this).find('> .dropdown-menu').stop(true, true).delay(200).fadeIn(500);
				}, function() {
					$(this).find('> .dropdown-menu').stop(true, true).delay(200).fadeOut(500);
				});
				
				$('.dropdown-menu').on('click', function(e) {
			      if($(this).hasClass('dropdown-menu-form')) {
			          e.stopPropagation();
			      }
			  });

				var pageContent = $('.single-post .post-content, .single-resource .post-content, .blog .post-content, .archive .post-content');
				var point = $('.navbar-wrapper .container');
				var offset = parseInt(point.css('margin-left'));
				pageContent.css({
					'margin-left': offset,
				});

				$(window).resize(function() {
					var offset = point.offset().left + parseInt(point.css('padding-left'));
					pageContent.css('padding-left', offset);

				});

				function new_map( $el ) {

					function add_marker( $marker, map ) {

					// var
					var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
					var markerType = $marker.attr('data-identifiers');

					// create marker
					var marker = new google.maps.Marker({
						position  : latlng,
						map     : map,
						type: markerType
					});



					// add to array
					map.markers.push( marker );

					// if marker contains HTML, add it to an infoWindow
					if( $marker.html() )
					{
						// create info window
						var infowindow = new google.maps.InfoWindow({
							content   : $marker.html()
						});

						// show info window when marker is clicked
						google.maps.event.addListener(marker, 'click', function() {

							infowindow.open( map, marker );

						});
					}

				}

				function center_map( map ) {

					console.log('center map');

					// vars
					var bounds = new google.maps.LatLngBounds();

					// loop through all markers and create bounds
					$.each( map.markers, function( i, marker ){

						var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

						bounds.extend( latlng );

					});

					// only 1 marker?
					if( map.markers.length === 1 )
					{
						// set center of map
						map.setCenter( bounds.getCenter() );
						map.setZoom( 12 );
					}
					else
					{
						// fit to bounds
						map.fitBounds( bounds );
					}

				}

					// var
					var $markers = $el.find('.marker');

					// vars
					var args = {
						zoom    : 12,
						center    : new google.maps.LatLng(0, 0),
						mapTypeId : google.maps.MapTypeId.ROADMAP
					};
					
					
					// create map           
					var map = new google.maps.Map( $el[0], args);
					
					
					// add a markers reference
					map.markers = [];
					
					
					// add markers
					$markers.each(function(){

						add_marker( $(this), map );
						
					});
					
					
					// center map
					center_map( map );
					
					
					// return
					return map;
					
				}

				var map = null;

				$(document).ready(function(){
						map = new_map( $('.acf-map') );
						$('.sort-item a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
							google.maps.event.trigger(map, "resize");
							map.setZoom(12);
						});
				});

				$('.filter-submit').on('click', function(e){
					var query_string = '?';
					var include = '';
					$('.sort-field').each(function(){
						var include = '';
						var query_id = $(this).attr('id');
						$(this).find('input:checked').each(function(){
							include += $(this).attr('value') + ',';
						});
						query_string += query_id + '=' + include + '&';
					});
					window.location.href = window.location.pathname+query_string;
					e.preventDefault();

				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'post_type_archive_project': {
			init: function() {
			},
			finalize: function() {
				
			}
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
